// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-home-page-js": () => import("/opt/build/repo/src/Templates/HomePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-products-page-js": () => import("/opt/build/repo/src/Templates/ProductsPage.js" /* webpackChunkName: "component---src-templates-products-page-js" */),
  "component---src-templates-services-page-js": () => import("/opt/build/repo/src/Templates/ServicesPage.js" /* webpackChunkName: "component---src-templates-services-page-js" */),
  "component---src-templates-about-us-page-js": () => import("/opt/build/repo/src/Templates/AboutUsPage.js" /* webpackChunkName: "component---src-templates-about-us-page-js" */)
}

